import React from 'react'
import cx from 'classnames'
import styles from './Heading.module.scss'
import { HeadingProps } from '~types'

const Heading = ({
  children,
  level,
  levelDisplay,
  isEyebrow = false,
  isUppercase = false,
  justify = 'left',
  title = '',
  margin,
  gradient = false,
  ...restStyle
}: HeadingProps) => {
  const classNames = cx([
    styles[`heading`],
    styles[`level-${levelDisplay ? levelDisplay : level}`],
    styles[justify === 'center' ? `justify-${justify}` : ''],
    styles[isEyebrow ? 'eyebrow' : ''],
    styles[isUppercase ? 'to-uppercase' : ''],
    styles[gradient ? 'gradient' : '']
  ])
  const HeadingTag = `h${level}`

  return <HeadingTag className={classNames} style={{ margin, ...restStyle }} dangerouslySetInnerHTML={{ __html: children ? children : title }} />
}

export default Heading
