import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { useRouter } from 'next/router'
import { Video } from '@msgtechnology/sphere'
import { SLUG_GALLERY } from '~constants'
import breakpoints from '../../../styles/variables.breakpoints.module.scss'
import styles from './ShowcaseGallery.module.scss'
import { VimeoProps, ShowcaseGalleryProps } from '~types'
import cx from 'classnames'

const ShowcaseGallery = ({ threeVideoTitleIDs, playlist }: ShowcaseGalleryProps) => {
  const [videos, setVideos] = useState<VimeoProps[]>([])
  const router = useRouter()
  const breakpointMd = Number(breakpoints['md'])

  useEffect(() => {
    if (playlist && Array.isArray(playlist)) {
      const matchedVideos = playlist.filter(video => {
        return threeVideoTitleIDs.includes(video.sId)
      })
      setVideos(matchedVideos)
    }
  }, [threeVideoTitleIDs, playlist])

  const settings = {
    arrows: false,
    dots: false,
    slidesToShow: 3,
    appendDots: (dots: React.ReactNode) => (
      <div>
        <ul className={cx('slick-dots', styles.tiles)}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: breakpointMd,
        settings: {
          dots: true,
          slidesToShow: 1,
          initialSlide: 0 // not working in react-slick v.0.30.2 bug: https://github.com/akiran/react-slick/issues/2027
        }
      }
    ]
  }

  const VideoSlide = ({ video, style, index, ...props }: { video: VimeoProps; style?: React.CSSProperties; index: number }) => {
    let padding
    if (index === 0) {
      padding = { paddingRight: '12px' }
    }
    if (index === 1) {
      padding = { padding: '0 12px' }
    }
    if (index === 2) {
      padding = { paddingLeft: '12px' }
    }
    const combinedStyle = { ...style, ...padding }
    return (
      <div {...props} style={combinedStyle}>
        <div className={styles['video-container']}>
          <Video
            id={video.sId}
            video={video.video}
            thumbnail={video.thumbnail}
            displayThumbnail={true}
            autoplay={true}
            enableVideoHover={true}
            showTags={true}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Create a spectacle not an ad</div>
      <div className={styles.subheading}>see how others have done it</div>
      <Slider {...settings} className={styles['slick']}>
        {videos.map((video, index) => (
          <VideoSlide key={index} index={index} video={video} />
        ))}
      </Slider>
      <button className={styles['button']} onClick={() => router.push(SLUG_GALLERY)}>
        View all
      </button>
    </div>
  )
}

export default ShowcaseGallery
