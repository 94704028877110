import React, { useState } from 'react'
import { Container } from 'react-grid-system'
import styles from './FAQ.module.scss'
import { DownChevronIcon } from '~elements'
import { FAQProps } from '~types'

const FAQ = ({ content }: FAQProps) => {
  const [visibleAnswers, setVisibleAnswers] = useState<boolean[]>(Array(content.length).fill(false))

  const toggleAnswerVisibility = (index: number) => {
    setVisibleAnswers(prevState => prevState.map((visibility, i) => (i === index ? !visibility : visibility)))
  }

  return (
    <Container className={styles['container']}>
      <div className={styles['heading']}>Frequently Asked Questions</div>
      {content.map((block, index) => (
        <div key={index} className={`${styles['faq-item']}`}>
          <div className={styles['question']} onClick={() => toggleAnswerVisibility(index)}>
            {block.question}
            <span className={styles['icon']}>
              <div className={`${styles['icon']} ${visibleAnswers[index] ? styles['rotated'] : ''}`}>
                <DownChevronIcon />
              </div>
            </span>
          </div>
          <div
            className={`${styles['answer']} ${visibleAnswers[index] ? styles['visible'] : ''}`}
            dangerouslySetInnerHTML={{ __html: block.answer }}
          />
        </div>
      ))}
    </Container>
  )
}

export default FAQ
